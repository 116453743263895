import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { GridRow } from '@naf/grid';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0;
`;

const AnimatedText = styled(Text)`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 25px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 25px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  * > a {
    color: ${nafColor.primary.park};
  }
  ol {
    list-style: none;
    counter-reset: item;
    margin-top: 48px;
    margin-left: 0;
    padding-left: 60px;
  }

  li {
    counter-increment: item;
    position: relative;
    margin-bottom: 0;

    p {
      position: relative;
      top: -36px;
      vertical-align: middle;
      display: inline-block;
      margin: 0 0 26px 0;
    }
  }

  li:before {
    position: absolute;
    left: -60px;
    top: -41.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 10px;
    margin-bottom: 8px;
    content: counter(item);
    background: #d3eac2;
    color: #062e2a;
    text-align: center;
    font-weight: 400;
  }

  li:not(:last-child):after {
    content: '';
    position: absolute;
    left: -41px;
    top: 5px;
    bottom: 0;
    height: calc(100% - 5px);
    width: 3px;
    background-image: linear-gradient(to bottom, #062e2a 33%, rgba(255, 255, 255, 0) 0%);
    background-radius: 3px;
    background-size: 3px 10px;
    background-repeat: repeat-y;
    z-index: -1;
  }
`;

const TextWrapper = styled.div``;

const Header = styled(Text)`
  margin-top: 58px;
  max-width: 545px;
`;

const StyledP = styled.p`
  margin: 0;
`;

const MemberNumber = styled(Text)`
  margin-top: 0;
`;

const WhatNow = styled(Text)`
  margin-top: ${spacing.space48};
`;

const SummaryWrapper = styled.div`
  background: ${nafColor.signature.yellow10};
  margin-left: 0;
  @media (min-width: ${breakpoints.m}) {
    margin-left: 10px;
  }
`;

const Summary = styled.div`
  padding: ${spacing.space32};
`;

const SummaryHeading = styled(Text)`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.375rem;
`;

const LineWrapper = styled.div<{ withTopBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  border-top: ${({ withTopBorder }) => (withTopBorder ? '1px solid rgba(10, 10, 10, 0.12)' : undefined)};
  border-top: ${({ withTopBorder }) => (withTopBorder ? '1px solid rgba(10, 10, 10, 0.12)' : undefined)};
  margin-top: ${({ withTopBorder }) => (withTopBorder ? '32px' : undefined)};
  padding-top: ${spacing.space32};
`;

const LineText = styled(Text)`
  margin: 0;
  margin-bottom: 3px;
`;

const Price = styled(Text)`
  margin: 0;
  margin-bottom: 3px;
  min-width: 70px;
  text-align: right;
`;

const TotalPrice = styled(Text)`
  margin: 0;
  font-size: 1.375rem;
`;

const LinkWrapper = styled(GridRow)`
  margin-top: ${spacing.space80};
`;

export default {
  LoaderWrapper,
  AnimatedText,
  Wrapper,
  TextWrapper,
  Header,
  MemberNumber,
  StyledP,
  WhatNow,
  SummaryWrapper,
  Summary,
  SummaryHeading,
  LineWrapper,
  LineText,
  Price,
  TotalPrice,
  LinkWrapper,
};
